.bvfwrapper {
    display: grid;
    place-content: center;
    background-color: var(--background-color);
    min-height: 1vh;
    font-family: "Oswald", sans-serif;
    font-size: 100px;
    font-weight: 700;
    text-transform: uppercase;
    color: white;
}

/* Media query for screens with a maximum width of 767px (typical for mobile devices) */
@media only screen and (max-width: 767px) {
    .bvfwrapper {
        font-size: 50px;
    }
}

  .bvfwrapper > div {
    grid-area: 1/1/-1/-1;
  }
  .bvftop {
    clip-path: polygon(0% 0%, 100% 0%, 100% 48%, 0% 58%);
  }
  .bvfbottom {
    clip-path: polygon(0% 60%, 100% 50%, 100% 100%, 0% 100%);
    color: white;
    background: -webkit-linear-gradient(177deg, black 53%, var(--text-color) 65%);
    background: linear-gradient(177deg, black 53%, var(--text-color) 65%);
    background-clip: text;
    -webkit-background-clip: text;
    transform: translateX(-0.02em);
  }
  